import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import AOS from 'aos'
import { Helmet } from "react-helmet"
import Layout from "../layouts"

function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progress-bar").style.width = scrolled + "%";
}

class UniversityTemplate extends Component {
  
  componentDidMount() {
    AOS.init();
    window.onscroll = function() {
      myFunction();
      AOS.refresh();
    };
  }

  render() {
    const post = this.props.data.wordpressWpUniversity
    let house = '';
    let acfColor = '#F3A738';

    return (
      <Layout>
       <Helmet>
          <meta charSet="utf-8" />
          <title>Excel | {post.title}</title>
          
        </Helmet>
        <div className="progress"><div id="progress-bar" style={{backgroundColor: acfColor}}></div></div>
        <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </Layout>
    )
  }
}

UniversityTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default UniversityTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpUniversity(id: { eq: $id }) {
      title
      content
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
